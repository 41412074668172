<template>
  <div class="readme-article">
    <h2 id="客户阶段">2.客户阶段</h2>
    <h3 id="阶段列表">2.1阶段列表</h3>
    <p>查看客户所在阶段情况，支持拖拽或者客户名片右下角...按钮改变客户阶段，点击右上角未设置客户可查看未设置阶段客户列表及设置客户阶段。</p>
    <p>
      <img src="@/assets/img/scrm/3-4.png" />
    </p>
    <h3 id="阶段设置">2.2阶段设置</h3>
    <p>查看已添加客户阶段列表，包括阶段名称、阶段描述、员工跟进提醒等信息。</p>
    <p>
      <img src="@/assets/img/scrm/3-5.png" />
    </p>
    <h3 id="阶段编辑">2.3阶段编辑</h3>
    <p>阶段新增编辑，必须填写阶段名称，可根据需求设置员工管理员跟进提醒。</p>
    <p>
      <img src="@/assets/img/scrm/3-6.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm3-2",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>